<template>
  <div class="card">
    <div class="position" :class="{'leaderBg1': info.positionLevel === 1, 'leaderBg2': info.positionLevel === 2, 'leaderBg3': info.positionLevel === 3 }">
      {{$t('president')}}
    </div>
    <div class="infoBox">
      <el-avatar
          class="head"
          shape="square"
          :fit="'contain'"
          :src="info.avatar ? info.avatar : defaultAvatar"
      />
      <div class="info">
        <div class="nameTitle">{{$t('name')}}</div>
        <div class="name">{{ info.name_en | priorFormat(info.name_zh, LOCALE) }}</div>
        <div class="introduceTitle">
          <el-button
              type="text"
              :disabled="!info.introduce"
              @click="handleIntroduceShow">
            <span class="introduceBtn">
              {{$t('Personal_introduction')}}
            </span>
            <i class="el-icon-arrow-down introduceIcon"></i>
          </el-button>
        </div>
        <div v-show="introduceShow" class="introduce" :title="info.introduce | textFormat">
          {{ info.introduce | textFormat }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "leaderCard",
    props: {
      info: {
        type: Object,
        default:function () {
          return {
            positionLevel: 1,   // 职位标题颜色,以及文案
            avatar: '',   // 头像
            name_en: '',
            name_zh: '',
            introduce: ''
          }
        }
      },
    },
    data() {
      return {
        defaultAvatar: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/head.png',
        introduceShow: false
      }
    },
    methods: {
      handleIntroduceShow() {
        this.introduceShow = !this.introduceShow
      }
    }
  }
</script>

<style scoped lang="less">
.card {
  width: 519px;
  height: 285px;
  background: #FFFFFF;
  padding-top: 12px;
  .position {
    width: 119px;
    height: 34px;
    padding-left: 24px;
    line-height: 34px;
    letter-spacing:15px;
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
  }
  .leaderBg1 {
    background-image: url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/leaderBg1.png');
  }
  .leaderBg2 {
    background-image: url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/leaderBg2.png');
  }
  .leaderBg3 {
    background-image: url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/leaderBg3.png');
  }
  .infoBox {
    margin: 20px 0 0 24px;
    overflow: hidden;
    .head {
      width: 126px;
      height: 189px;
      float: left;
    }
    .info {
      float: left;
      padding: 24px 28px 0 24px;
      .nameTitle {
        color: #333333;
        font-weight: bold;
        font-size: 20px;
      }
      .name {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
        color: #9A9A9A;
      }
      .introduceTitle {
        margin-top: 28px;
        .introduceBtn {
          font-size: 16px;
          font-weight: bold;
          color: #666666;
        }
        .introduceIcon {
          color: #999999;
          margin-left: 6px;
        }
      }
      .introduce {
        width: 317px;
        margin-top: 6px;
        font-size: 14px;
        font-weight: 400;
        color: #9A9A9A;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; //多行在这里修改数字即可
        -webkit-box-orient: vertical;
        word-wrap: break-word;
      }
    }
  }
}
</style>